import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import WhitePaperThankYou from "../components/white-paper/white-paper-thank-you"

 const WhitepaperSuccessPage = ({data, pageContext}) => {

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KalBytes - Thank you page</title>
        <link rel="canonical" href="https://www.kalbytes.de/" />
      </Helmet>

      <div>
        <WhitePaperThankYou/>
      </div>
    </Layout>
  )
}

export default WhitepaperSuccessPage;